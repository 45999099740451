import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Index.vue'
const Home = resolve => require(['@/components/Home.vue'], resolve)
const Index = resolve => require(['@/views/Index.vue'], resolve)
const Register = resolve => require(['@/views/Register.vue'], resolve)
const IndexDetails = resolve => require(['@/components/GlanceOver/IndexDetails.vue'], resolve)

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        children: [
            { path: "/", component: Index },
        ]
    },
    {
        path: '/register',
        component: Home,
        children: [
            { path: "/register", component: Register },
        ]
    },
    {
        name: 'indexDetails',
        path: '/indexDetails',
        component: IndexDetails
        // children: [
        //     { path: "/indexDetails", component: IndexDetails },
        // ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
