<template>
    <svg aria-hidden="true" :class="svgClass" v-bind="$attrs">
        <use :xlink:href="icon" />
    </svg>
</template>

<script>
export default {
    name: 'svg-icon',
    props: {
        name: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ""
        }
    },
    computed: {
        icon() {
            return `#icon-${this.name}`
        },
        svgClass() {
            if (this.className) {
                return `svg-icon ${this.className}`;
            } else {
                return `svg-icon`;
            }
        }
    }
}
</script>

<style scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>